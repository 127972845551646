<template>
  <div>
    <div>
      <b-card bg-variant="danger" text-variant="white" header="Fatal error" class="text-center">
        
        <div class="w-100 text-center">
            <br><br><br>
            <h2>
                403 Forbidden
            </h2>
            <br><br>
            <p>
                This can be caused by invalid parameters, data, changed system state or other error.
            <br>
                Try again. If the problem persists, contact support.
            <br>
                Thank you.
            </p>
            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{ name: 'default-view', params: { }}"
            >
                Back home
            </b-button>
            <br><br><br>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import { /*state, mutations*/ } from '@/store/index.js'

export default {
  components: { },

  computed: { },

  data( ) {
    return {
    }
  },

  mounted( ) { },

  methods: { }
}
</script>

<style scoped>
</style>